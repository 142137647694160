<template>
  <div class="column is-12">
    <base-table
      class="is-bordered is-narrow is-striped-2 is-fullwidth is-hoverable bankingTable"
      :columns="columnsData"
      :data="categoryList"
      :loading="loading"
    >
      <div
        slot="nameInfo"
        slot-scope="scope"
      >
        <div class="field">
          <span class="showModal">{{scope.row.name}}</span>
        </div>
      </div>
      <div
        class="field"
        slot="status"
        slot-scope="scope"
      >
        <input
          :id="`switchStatus-${scope.row.id}`"
          type="checkbox"
          name="switchStatusUser"
          class="switch is-rounded is-info is-small is-success"
          :checked="scope.row.active ? 'checked' : ''"
          @click="changeStatus(scope.row.id)"
        >
        <label
          :for="`switchStatus-${scope.row.id}`"
        >
        </label>
      </div>
      <div
        slot="config"
        slot-scope="scope"
      >
        <i class="material-icons icon-action" @click="openEdit(scope.row)">edit</i>
      </div>
    </base-table>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="pages"
      :action="paginator"
      :loading="loading"
    />
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CategoriesList',
  props: {
    categoryList: {
      type: Array
    },
    page: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    },
    paginator: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    changeStatus: {
      type: Function,
      default: () => {}
    },
    openEdit: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      columnsData: [
        {
          id: 'id',
          header: 'Id',
          class: 'column-mobile',
          accessor: (row) => `${row.id}`
        },
        {
          id: 'name',
          name: 'nameInfo',
          header: 'Nombre',
          class: 'column-mobile'
        },
        {
          id: 'created_at',
          header: 'Fecha de creación',
          class: 'column-mobile',
          accessor: (row) => moment(row.created_at).format('DD-MM-YYYY HH:mm')
        },
        { id: 'status', name: 'status', header: 'Estatus', class: 'column-mobile has-text-centered' },
        { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ]
    }
  }
}
</script>

<style scoped>
  .showModal {
    cursor: pointer;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
    font-size: 14px;
  }

  .icon-action:hover {
    color: var(--primary-color);
  }
</style>
